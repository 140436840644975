import React, {Component} from 'react';
import _ from 'underscore';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './tiles.scss';
import themeService from "../../../services/themeService";
import playlistService from "../../../services/playlistService";
import ScrollingWrapper from "../../../components/ScrollingWrapper";
import authService from "../../../services/authService";

class Tiles extends Component {
    constructor() {
        super();

        this.state = {
            items: [],
            search: "",
            searchFocus: false,
            searchResults: [],
            loading: true,
            addModal: false,
            addName: "",
            error: null
        };
    }

    componentDidMount() {
        const {location} = this.props;
        const params = new URLSearchParams(location.search);
        const searchFocus = params.get("s");

        if(searchFocus) {
            this.setState({searchFocus: true});
        }

        this.fetchLists()
    }

    async componentDidUpdate(oldProps) {
        const {page, location, favorites, allPlaylists} = this.props;
        const params = new URLSearchParams(location.search);
        const id = params.get("id");
        const searchFocus = params.get("s");

        const oldParams = new URLSearchParams(oldProps.location.search);
        const oldId = oldParams.get("id");
        const oldSearchFocus = oldParams.get("s");

        if (searchFocus && !oldSearchFocus) {
            this.setState({searchFocus: true});
        }

        if (page !== oldProps.page || id !== oldId || (allPlaylists && allPlaylists.length > 0 && oldProps.allPlaylists.length < 1)) {
            this.fetchLists();

            const listMenu = document.getElementsByClassName('listsMenu')[0];
            const backButton = document.getElementById('backBtn');
            const tile = document.getElementsByClassName('tile')[0];

            if (listMenu) {
                listMenu.scrollIntoView({
                    inline: 'start',
                    behavior: "smooth"
                });
            } else if (backButton) {
                backButton.scrollIntoView({
                    inline: 'start',
                    behavior: "smooth"
                });
            } else if (tile) {
                tile.scrollIntoView({
                    inline: 'start',
                    behavior: "smooth"
                });
            }
        } else if (page === "favorites" && oldProps.favorites.length !== favorites.length) {
            if (favorites.length > 0) {
                const fav = await playlistService.getPlaylists(favorites).then(({data}) => {
                    return data.data;
                }).catch(error => {
                    if (error.response) {
                        const {status} = error.response;
                        if (status === 403 || status === 401 || status === 404) {
                            this.logout();
                        } else if (status === 605) {
                            authService.logout();
                            window.location.replace("https://bcmstream.com/bcm-stream-trials")
                        }
                    }
                });

                const userLists = await playlistService.getUserPlaylists().then(({data}) => {
                    return data.data;
                }).catch(error => {
                    if (error.response) {
                        const {status} = error.response;
                        if (status === 403 || status === 401 || status === 404) {
                            this.logout();
                        } else if (status === 605) {
                            authService.logout();
                            window.location.replace("https://bcmstream.com/bcm-stream-trials")
                        }
                    }
                });

                userLists.forEach((list) => {
                    if (_.contains(favorites, list._id)) {
                        fav.push(list);
                    }
                })
                this.setState({loading: false, items: fav ? fav : []})
            } else {
                this.setState({items: []})
            }
        }
    }

    async fetchLists() {
        const {page, location, favorites, allPlaylists} = this.props;

        this.setState({items: [], searchResults: [], search: "", loading: true, title: null});
        switch (page) {
            case 'themes':
                const params = new URLSearchParams(location.search);
                const id = params.get("id");

                if (id) {
                    themeService.getTheme(id).then(({data}) => {
                        this.setState({loading: false, items: data.data.Playlists, title: data.data.Language})
                    }).catch(error => {
                        if (error.response) {
                            const {status} = error.response;
                            if (status === 403 || status === 401 || status === 404) {
                                this.logout();
                            } else if (status === 605) {
                                authService.logout();
                                window.location.replace("https://bcmstream.com/bcm-stream-trials")
                            }
                        }
                    })
                } else {
                    themeService.getAllThemes().then(({data}) => {
                        this.setState({loading: false, items: data.data})
                    }).catch(error => {
                        if (error.response) {
                            const {status} = error.response;
                            if (status === 403 || status === 401 || status === 404) {
                                this.logout();
                            } else if (status === 605) {
                                authService.logout();
                                window.location.replace("https://bcmstream.com/bcm-stream-trials")
                            }
                        }
                    })
                }
                break;
            case 'playlists':
                if (allPlaylists.length > 0) {
                    this.setState({loading: false, items: allPlaylists})
                }
                break;
            case 'user':
                playlistService.getUserPlaylists().then(({data}) => {
                    this.setState({items: data.data, loading: false})
                }).catch(error => {
                    if (error.response) {
                        const {status} = error.response;
                        if (status === 403 || status === 401 || status === 404) {
                            this.logout();
                        } else if (status === 605) {
                            authService.logout();
                            window.location.replace("https://bcmstream.com/bcm-stream-trials")
                        }
                    }
                });
                break;
            case 'favorites':
                if (favorites.length > 0) {
                    const fav = await playlistService.getPlaylists(favorites).then(({data}) => {
                        return data.data;
                    }).catch(error => {
                        if (error.response) {
                            const {status} = error.response;
                            if (status === 403 || status === 401 || status === 404) {
                                this.logout();
                            } else if (status === 605) {
                                authService.logout();
                                window.location.replace("https://bcmstream.com/bcm-stream-trials")
                            }
                        }
                    });

                    const userLists = await playlistService.getUserPlaylists().then(({data}) => {
                        return data.data;
                    }).catch(error => {
                        if (error.response) {
                            const {status} = error.response;
                            if (status === 403 || status === 401 || status === 404) {
                                this.logout();
                            } else if (status === 605) {
                                authService.logout();
                                window.location.replace("https://bcmstream.com/bcm-stream-trials")
                            }
                        }
                    });

                    userLists.forEach((list) => {
                        if (_.contains(favorites, list._id)) {
                            fav.push(list);
                        }
                    })
                    this.setState({loading: false, items: fav ? fav : []})
                } else {
                    this.setState({loading: false, items: []})
                }
                break;
        }
    }

    logout = () => {
        const { history } = this.props;
        authService.logout();
        history.push("/login")
    };

    onClick = (tile) => {
        const {addPlaylist, interactive, history} = this.props;

        if(tile.Playlists) {
            history.push({
                search: `?id=${tile.ID}`
            });
        } else {
            if (interactive) {
                if (tile.UserList) {
                    history.push(`/player/list/user/${tile._id}`);
                } else {
                    history.push(`/player/list/${tile.ID}`);
                }
            } else {
                addPlaylist(tile);
            }
        }
    };

    createPlaylist = () => {
        const {t} = this.props;
        const {addName} = this.state;

        if (addName) {
            playlistService.createUserPlaylist(addName).then(({data}) => {
                this.setState({addModal: false})
                this.fetchLists();
            }).catch(error => {
                if(error.response) {
                    const { status } = error.response;
                    if(status === 403 || status === 401) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/bcm-stream-trials")
                    }
                }
            });
        } else {
            this.setState({error: t('error_no_name')})
        }

    }
    scrollTo = (character) => {
        const tile = document.getElementById(character);
        if (tile) {
            tile.scrollIntoView({
                inline: 'start',
                behavior: "smooth"
            });
        }
    };

    onSearch = (value) => {
        const {items} = this.state;
        const {i18n} = this.props;

        const lang = i18n.language.substr(0,2);
        const searchValue = value.toLowerCase().replace(/\s/g, '');
        if(value.length > 0) {
            const searchResults = items.filter((item) => {
                if(item.Language && item.Language[lang]) {
                    let match = false;
                    if(item.Language[lang].SubjectText) {
                        const subject = item.Language[lang].SubjectText.toLowerCase().replace(/\s/g, '');
                        match = subject.indexOf(searchValue) > -1
                    }

                    if(!match) {
                        if(item.Language[lang].Description) {
                            const description = item.Language[lang].Description.toLowerCase().replace(/\s/g, '');
                            match = description.indexOf(searchValue) > -1
                        }
                    }

                    return match
                }
            });

            this.setState({searchResults, search: value})
        } else {
            this.setState({searchResults: [], search: ""})
        }
    }

    availableCharacters = (tiles) => {
        return [...new Set(tiles.map(tile => tile.Language && tile.Language.nl ? tile.Language.nl.SubjectText.toLowerCase().charAt(0) : null))];
    };

    redirectToOverview = () => {
        const {page, history, interactive} = this.props;
        if(page === "themes") {
            if (interactive){
                //history.push("/player/lists/playlists?s=true")
            } else {
                history.push("/player/playlists", {
                    search: `?s=true`
                })
            }
        }
    }

    loadingText = () => {
        const {page, location, t} = this.props;

        switch(page) {
            case 'themes':
                const params = new URLSearchParams(location.search);
                if(params.get("id")) {
                    return t('thema') + " " + t('loaded') + "...";
                };

                return t('themes') + " " + t('loading') + "...";
            case 'playlists':
                return t('playlists') + " " + t('loading') + "...";
            case 'user':
                return t('playlists') + " " + t('loading') + "...";
            case 'favorites':
                return t('favorites') + " " + t('loading') + "..."
        }
    };

    renderEmptyText = () => {
        const {page, location, t} = this.props;

        switch(page) {
            case 'themes':
                const params = new URLSearchParams(location.search);
                if(params.get("id")) {
                    return t('no_playlists');
                };
                return t('no_themes');
            case 'playlists':
                return t('no_playlists');
            case 'user':
                return t('no_playlists');
            case 'favorites':
                return t('no_favorites')
        }
    };
    render() {
        const {activeLists, page, history, location, favorites, addFavorite, mobile, t, i18n, user} = this.props;
        const {items, searchResults, search, loading, searchFocus, title, addModal, error} = this.state;

        const params = new URLSearchParams(location.search);
        const id = params.get("id");

        let workTiles = search.length > 0 ? searchResults : items;
        let key = page;

        const lang = i18n.language.substr(0,2);

        workTiles.sort((a, b) => {
            const titleA = a.Language && a.Language[lang] ? a.Language[lang].SubjectText.toLowerCase() : '';
            const titleB = b.Language && b.Language[lang] ? b.Language[lang].SubjectText.toLowerCase() : '';

            return ( titleA > titleB) ? 1 : ((titleB > titleA) ? -1 : 0);
        });
        const themeTitle = title && title[lang] ? title[lang].SubjectText : "";
        const showSearch = true;
        return (
            <div id={"tileContainer"} className={"tilesContainer"}>
                {!loading ? (
                    <>
                        {addModal ? (<div className={"infoPopup"}>
                            <div className={"closeBtn"} onClick={() => {this.setState({addModal: null})}}>
                                <img src={"/icons/cross.png"}/>
                            </div>
                            <div className={"actions"}>
                                { error ? (
                                    <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                        <div className="inner">
                                            <li>
                                                <strong>{error}</strong>
                                            </li>
                                        </div>
                                    </div>
                                ) : null}
                                <label htmlFor="name">
                                    {t('playlist_name')}</label>
                                <input name="name" type="text"
                                       value={this.state.addName}
                                       placeholder={t('playlist_name')}
                                       onChange={(e) => {
                                    this.setState({addName: e.target.value})
                                }} className="input"></input>
                                <div className={"trackButton"} onClick={() => {this.createPlaylist()}}>{t('create_playlist')}</div>
                            </div>
                        </div>) : null}
                        {!mobile && id ? <div className={"themeHeader"}><img onClick={() => {
                                history.push({
                                    search: ''
                                })
                        }} id={"backBtn"} className={"backButton"} src={"/icons/back.svg"}/><div className={"themeTitle"}>{themeTitle}</div></div> : null}
                        <input className={"searchBox"} placeholder={t('search') + "..."} autoFocus={searchFocus} onFocus={() => {this.redirectToOverview()}} onChange={e => this.onSearch(e.target.value)} />
                    <ScrollingWrapper mobile={mobile}>
                        <div key={key} className={"tileList"}>
                        {search && searchResults.length < 1 ?
                            <div className={"noResults"}>{t('no_results')}...</div>
                            : workTiles.length > 0 ? workTiles.map((tile, index) => {
                            const info = tile.Language && tile.Language[lang] ? tile.Language[lang] : {SubjectText : "", Description: ""};
                            const favorite = _.contains(favorites, tile.ID) || _.contains(favorites, tile._id);

                            return (
                                <div key={index} id={info.SubjectText.toLowerCase().charAt(0)}
                                     onClick={() => this.onClick(tile)}
                                     className={"tile" + (_.findWhere(activeLists, {id: tile.ID}) ? " active" : "")}>
                                    {(page === "themes" && !id) ?
                                        <div className={"themeIcon"}>
                                            <div className={"listCount"}>{tile.Playlists ? tile.Playlists.length : 0}</div>
                                            <img className={"tileImgTheme"}
                                                 src={tile.PictureUrl ? tile.PictureUrl : "/icons/tabs.svg"} onError={(e)=>{e.target.onerror = null; e.target.src="/icons/themeborder.svg"}}/>
                                            <img className={"tileBackgroundTheme"}
                                                 src={"/icons/themeborder.svg"}/>
                                        </div>
                                        : <div className={"playlistIcon"}><img className={"tileImg"} src={tile.PictureUrl ? tile.PictureUrl : "/icons/tabs.svg"} onError={(e)=>{e.target.onerror = null; e.target.src="/icons/themeborder.svg"}}/></div>}
                                    <div className={"tileInfo"}>
                                        <div className={"upper"}>
                                            <div className={"tileTitle"}><i>{info.SubjectText}</i></div>
                                            {!((page === "themes" && !id)) ? <div className={"tileFavorite" + (favorite ? " active" :"")}  onClick={(e) => {e.stopPropagation(); addFavorite(tile.ID ? tile.ID : tile._id)}}><img src={"/icons/favorite.svg"}/></div> : null}
                                        </div>
                                        <p className={"tileTagline"}>{info.Description}</p>
                                    </div>
                                </div>
                            )
                        }) :
                                <>
                                    <div className={"noResults"}>{this.renderEmptyText()}</div>
                                </>

                        }
                            {  page === "user" ? (
                                <div className={"emptyBox"}>
                                    <img className={"addButton"} src={"/icons/plus.svg"} onClick={() => {this.setState({addModal: true})}}/>
                                </div>
                            ) : null}
                    </div>
                    </ScrollingWrapper>
                    </>
                ) : (
                    <div className={"loadingSpinner"}>
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={"loadingText"}>
                            {this.loadingText()}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(withTranslation()(Tiles));
