import React, {Component, Fragment} from "react";
import styled from 'styled-components'

class ScrollingWrapper extends Component {
    state = { hasScrolled: false }

    componentDidMount() {
        this.scrollingWrapper.addEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
        if (this.scrollingWrapper.scrollTop > 300 && !this.state.hasScrolled) {
            this.setState({ hasScrolled: true })
        } else if (this.scrollingWrapper.scrollTop < 300 && this.state.hasScrolled) {
            this.setState({ hasScrolled: false })
        }
    }

    scrollToTop = () => {
        this.scrollingWrapper.scrollTo({top: 0, behavior: 'smooth'});
    }

    reference = id => ref => {
        this[id] = ref
    }


    render() {
        const {mobile} = this.props;
        return (
            <Fragment>
                {!mobile ? this.state.hasScrolled && (
                    <ScrollToTopIconContainer onClick={this.scrollToTop}>
                        <Button><img src={"/icons/up.svg"} /></Button>
                    </ScrollToTopIconContainer>
                ) : this.state.hasScrolled && (
                    <ScrollToTopIconMobileContainer onClick={this.scrollToTop}>
                        <Button><img src={"/icons/up.svg"} /></Button>
                    </ScrollToTopIconMobileContainer>
                )}
                <ScrollingWrapperContainer className={this.props.mobile ? "mobile" : ""} ref={this.reference('scrollingWrapper')}>
                    {this.props.children}
                </ScrollingWrapperContainer>
            </Fragment>
        )
    }
}

export default ScrollingWrapper;

const ScrollingWrapperContainer = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  position: relative;
`

const ScrollToTopIconContainer = styled.div`
  position: absolute;
  bottom: 130px;
  right: 40px;
  z-index: 2;
  cursor: pointer;
  opacity: 0.4;
  text-align: center;
  &:hover {
    opacity: 1;
    animation: wiggle 1s ease;
    animation-iteration-count: 1;
  }
  @keyframes wiggle {
    20% { transform: translateY(6px); }
    40% { transform: translateY(-6px); }
    60% { transform: translateY(4px); }
    80% { transform: translateY(-2px); }
    100% { transform: translateY(0); }
  }
`

const ScrollToTopIconMobileContainer = styled.div`
  position: absolute;
  bottom: 200px;
  right: 40px;
  z-index: 2;
  cursor: pointer;
  opacity: 0.4;
  text-align: center;
  &:hover {
    opacity: 1;
    animation: wiggle 1s ease;
    animation-iteration-count: 1;
  }
  @keyframes wiggle {
    20% { transform: translateY(6px); }
    40% { transform: translateY(-6px); }
    60% { transform: translateY(4px); }
    80% { transform: translateY(-2px); }
    100% { transform: translateY(0); }
  }
`

const Button = styled.div`
  background: #FFFFFF;
  border-radius: 15px;
  width: 4vw;
  height: 4vw;
  max-width: 60px;
  max-height: 60px;
  display: flex;
  align-itmes: center;
  justify-content: center;
  img {
    width: 2vw;
    max-width: 35px;
  }
  
  &:hover {
    background: #ff5722;
  }
`
