import './header.scss';
import React from "react";
import {withRouter} from "react-router-dom";

function Header({location, history, mobile, user, logout}) {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");

    return (
        <div className={"header" + (mobile ? " mobile" : "")}>
            {mobile && id ? <div className={"themeHeader"}><img onClick={() => {
                history.push({
                    search: ''
                })
            }} id={"backBtn"} className={"backButton"} src={"/icons/back.svg"}/></div> : null}
            <div className={mobile ? "userInfoMobile" : "userInfo"}><img className={"person"} src={"/icons/person.svg"}/>{user ? (user.firstName + " " + user.lastName): null} <img onClick={() => logout()} className={"logout"} src={"/icons/exit.svg"}/></div>
            <img className={"headerLogo"} src={"/img/BCM-Stream-headerlogo_en.svg"} />
        </div>
    )
}

export default withRouter(Header);
