import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './active.scss';
import playlistService from "../../../services/playlistService";
import _ from "underscore/modules/index-default";
import authService from "../../../services/authService";

class Active extends Component {
    constructor(props) {
        super(props);
    }

    onClick = (tile) => {
        const {interactive, history} = this.props;

            if (interactive) {
                if (tile.user) {
                    history.push(`/player/list/user/${tile.id}`);
                } else {
                    history.push(`/player/list/${tile.id}`);
                }
            }
    };
    render() {
        const {activeLists, addPlaylist, favorites, addFavorite, changePercentage, mobile, t, i18n} = this.props;

        const lang = i18n.language.substr(0,2);

        return (
            <div className={"mobileActiveContainer"}>
                <div key={"active"} className={mobile ? "mobileList" : "list"}>
                    {activeLists.length > 0 ? activeLists.map((tile, index) => {
                        const info = tile.details && tile.details[lang] ? tile.details[lang] : {SubjectText : "", Description: ""};
                        const favorite = _.contains(favorites, tile.id);
                        const percentage = tile.percentage;
                        const upActive = activeLists.length > 1 && Math.round(percentage + activeLists.length - 1) < 100;
                        const downActive = activeLists.length > 1 && Math.round(percentage - activeLists.length + 1) > 0;

                        return (
                            <div key={index}
                                 className={"tile " + (mobile ? "mobile" : "desktop")}>
                                <div className={"tileRowUpper"}>
                                    <div className={"tileTitle"}><i>{info.SubjectText}</i></div>
                                    <div className={"tileFavorite" + (favorite ? " active" :"")} onClick={(e) => {e.stopPropagation(); addFavorite(tile.id)}}><img src={"/icons/favorite.svg"}/></div>
                                </div>
                                <div className={"tileRowLower"} onClick={() => this.onClick(tile)}>
                                    <img className={"tileImg"}
                                         src={tile.image ? tile.image : "/icons/tabs.svg"}/>
                                    <p className={"tileInfo"}>{info.Description}</p>
                                </div>
                                <div className={"actionsBar"}>
                                    {percentage !== 100 ? (
                                        <div className={"percentageControl"}>
                                            <img className={"percentageBtn " + (!downActive ? " inactive" : "")} src={"/icons/min.svg"} onClick={() => changePercentage(tile.id, false)}/>
                                            <div className={"percentage"}>{percentage}%</div>
                                            <img className={"percentageBtn " + (!upActive ? " inactive" : "")} src={"/icons/plus.svg"} onClick={() => changePercentage(tile.id, true)}/>
                                        </div>
                                    ) : null}
                                    <img className={"removeBtn"} onClick={() => addPlaylist(tile)} src={"/icons/delete.svg"}/>
                                </div>
                            </div>
                        )
                    }) : <div className={"noResults"}>{t('no_active')}...</div>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation()(Active));
