import React, {Component} from 'react';
import {
    withRouter,
    Link
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './menu.scss';

class Menu extends Component {

    render() {
        const currentPath = this.props.location.pathname;
        const { active, t, interactive } = this.props;

        if (interactive) {
            return (
                <div className={"menuContainer"}>
                    <Link className={"link"} to='/player/lists/themes'>
                        <button className={"menuButton first" + (currentPath.startsWith("/player/lists") ? " active" : "")}>
                            <img src={"/icons/menu/themes.svg"} />
                            <div className={"buttonText"}>{t('playlists')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/active'>
                        <button className={"menuButton" + (currentPath.startsWith("/player/active") ? " active" : "")}>
                            { active && active > 0 ? (<div className={"activeCount"}>{active}</div>) : null}
                            <img src={"/icons/menu/active.svg"} />
                            <div className={"buttonText"}>{t('active_lists')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/queue'>
                        <button className={"menuButton" + (currentPath.startsWith("/player/queue") ? " active" : "")}>
                            <img src={"/icons/menu/lists.svg"} />
                            <div className={"buttonText"}>{t('queue')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/search'>
                        <button className={"menuButton" + (currentPath.startsWith("/player/search") ? " active" : "")}>
                            <img src={"/icons/menu/search.svg"} />
                            <div className={"buttonText"}>{t('search')}</div>
                        </button>
                    </Link>
                </div>
            )
        } else {
           return (
               <div className={"menuContainer"}>
                   <Link className={"link"} to='/player/favorites'>
                       <button className={"menuButton first" + (currentPath.startsWith("/player/favorites") ? " active" : "")}>
                           <img src={"/icons/menu/favorite.svg"} />
                           <div className={"buttonText"}>{t('favorites')}</div>
                       </button>
                   </Link>
                   <Link className={"link"} to='/player/themes'>
                       <button className={"menuButton" + (currentPath.startsWith("/player/themes") ? " active" : "")}>
                           <img src={"/icons/menu/themes.svg"} />
                           <div className={"buttonText"}>{t('themes')}</div>
                       </button>
                   </Link>
                   <Link className={"link"} to='/player/active'>
                       <button className={"menuButton" + (currentPath.startsWith("/player/active") ? " active" : "")}>
                           { active && active > 0 ? (<div className={"activeCount"}>{active}</div>) : null}
                           <img src={"/icons/menu/active.svg"} />
                           <div className={"buttonText"}>{t('active_lists')}</div>
                       </button>
                   </Link>
                   <Link className={"link"} to='/player/playlists'>
                       <button className={"menuButton" + (currentPath.startsWith("/player/playlists") ? " active" : "")}>
                           <img src={"/icons/menu/lists.svg"} />
                           <div className={"buttonText"}>{t('all_lists')}</div>
                       </button>
                   </Link>
                   {/**
                    <Link className={"link"} to='/player/profile'>
                    <button className={"menuButton" + (currentPath.startsWith("/player/profile") ? " active" : "")}>
                    <img src={"/icons/menu/lists.svg"} />
                    <div className={"buttonText"}>Profiel</div>
                    </button>
                    </Link>**/}

               </div>
           )
        }
    }
}

export default withRouter(withTranslation()(Menu));
