import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './listContent.scss';
import playlistService from "../../../services/playlistService";
import ScrollingWrapper from "../../../components/ScrollingWrapper";
import authService from "../../../services/authService";
import {PlaylistItem} from "./components/playlistItem";
import _ from "underscore";
import Select from 'react-select'

class ListContent extends Component {
    constructor() {
        super();

        this.state = {
            list: null,
            title: null,
            userLists: [],
            selectedUserList: null,
            search: "",
            searchFocus: false,
            searchResults: [],
            currentTrack: null,
            selectedSong: null,
            addToUserlist: false,
            addListToList: false,
            error: null,
            existsInList: false,
            loading: true,
            removing: false,
            addName: "",
            addNew: false
        };
    }

    async componentDidMount() {
        this.fetchList()
        this.fetchUserLists()
    }

    fetchList = async () => {
        const {match, user} = this.props;
        const id = match.params.id;
        let list;

        if (user) {
            list = await playlistService.getUserPlaylist(id).then(({data}) => {
                return data.data;
            }).catch((error) => {
                if (error.response) {
                    const {status} = error.response;
                    if (status === 404) {
                        this.setState({loading: false})
                    } else if (status === 403 || status === 401) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/bcm-stream-trials")
                    }
                }
            });
        } else {
            list = await playlistService.getPlaylist(id).then(({data}) => {
                return data.data;
            }).catch((error) => {
                if (error.response) {
                    const {status} = error.response;
                    if (status === 404) {
                        this.setState({loading: false})
                    } else if (status === 403 || status === 401) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/bcm-stream-trials")
                    }
                }
            });
        }


        if (list) {
            this.setState({loading: false, list: list})
        } else {
            this.setState({loading: false})
        }
    }

    fetchUserLists() {
        this.setState({userLists: []});
        playlistService.getUserPlaylists().then(({data}) => {
            this.setState({userLists: data.data})
        }).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/bcm-stream-trials")
                }
            }
        });
    }

    addSongToUserList = async (songId, accepted) => {
        const {t} = this.props;
        const {selectedUserList, userLists, addName} = this.state;

        let listId = selectedUserList ? selectedUserList : null;

        if (addName) {
            await playlistService.createUserPlaylist(addName).then(({data}) => {
                listId = data.id;
                this.setState({addNew: false, addName: null});
                this.fetchUserLists();
            }).catch(error => {
                if(error.response) {
                    const { status } = error.response;
                    if(status === 403 || status === 401) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/bcm-stream-trials")
                    }
                }
            });
        }

        if (listId) {
            const playlist = _.findWhere(userLists, {_id: selectedUserList});
            if (!accepted && playlist && playlist.Songs.includes(songId)) {
                this.setState({existsInList: true, addToUserlist:false})
            } else {
                playlistService.addToUserPlaylist(songId, listId).then(() => {
                    this.fetchList();
                    this.fetchUserLists();
                }).catch(error => {
                    if (error.response) {
                        const {status} = error.response;
                        if (status === 403 || status === 401 || status === 404) {
                            this.logout();
                        } else if (status === 605) {
                            authService.logout();
                            window.location.replace("https://bcmstream.com/bcm-stream-trials")
                        }
                    }
                });

                this.setState({loading: false, selectedUserList: null, existsInList: false, addToUserlist:false, currentTrack: null})
            }
        } else {
            this.setState({error: t('error_no_list')})
        }
    }

    removeFromUserlist = async (songIndex) => {
        const {match} = this.props;
        const id = match.params.id;

        playlistService.removeFromUserPlaylist(songIndex, id).then(() => {
            this.fetchList();
        }).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/bcm-stream-trials")
                }
            }
        });

        this.setState({loading: false, currentTrack: null})
    }

    addListToUserList = async () => {
        const {t} = this.props;
        const {selectedUserList, list, userLists, addName} = this.state;

        let listId = selectedUserList ? selectedUserList : null;

        if (addName) {
            await playlistService.createUserPlaylist(addName).then(({data}) => {
                listId = data.id;
                this.setState({addNew: false, addName: null});
                this.fetchUserLists();
            }).catch(error => {
                if(error.response) {
                    const { status } = error.response;
                    if(status === 403 || status === 401) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/bcm-stream-trials")
                    }
                }
            });
        }

        if (listId) {
            this.setState({addListToList: false})
            const songs = list.Songs.map(song => song.SongID);
            playlistService.addSongsToUserPlaylist(songs, listId).then(({data}) => {
                this.fetchUserLists();
                this.setState({ loading: false, selectedUserList: null})
            }).catch(error => {
                if (error.response) {
                    const {status} = error.response;
                    if (status === 403 || status === 401 || status === 404) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/bcm-stream-trials")
                    }
                }
            });
        } else {
            this.setState({error: t('error_no_list')})
        }
    }

    removeUserList = () => {
        const {history} = this.props;
        const {list} = this.state;

        playlistService.removeUserPlaylist(list._id).then(() => {
            this.setState({ loading: false})
            history.goBack()
        }).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/bcm-stream-trials")
                }
            }
        });
    }

    logout = () => {
        const { history } = this.props;
        authService.logout();
        history.push("/login")
    };

    onSearch = (value) => {
        const {list} = this.state;

        const searchValue = value.toLowerCase().replace(/\s/g, '');
        if(list && list.Songs && searchValue.length > 0) {
            const searchResults = list.Songs.filter((item) => {
                let match = false;
                if(item.Artist) {
                    const artist = item.Artist.toLowerCase().replace(/\s/g, '');
                    match = artist.indexOf(searchValue) > -1
                }

                if(!match) {
                    if(item.Title) {
                        const title = item.Title.toLowerCase().replace(/\s/g, '');
                        match = title.indexOf(searchValue) > -1
                    }
                }

                return match
            });

            this.setState({searchResults, search: value})
        } else {
            this.setState({searchResults: [], search: ""})
        }
    }

    render() {
        const {history, mobile, addToPlaylist, addPlaylist, activeLists, user, t} = this.props;
        const {list, searchResults, search, currentTrack, loading, addToUserlist, userLists, addListToList, error, selectedSong, existsInList, removing, addNew} = this.state;
        const {i18n} = this.props;

        const lang = i18n.language.substr(0,2);

        const id = "0"
        const listTitle = list && list.Language && list.Language[lang] && list.Language[lang].SubjectText ? list.Language[lang].SubjectText : "";
        const active = _.findWhere(activeLists, {id: list && (list.ID || list._id)}) ? " active" : "";

        const options = userLists.map((l) => {return { value: l._id, label: (l.Language && l.Language[lang] && l.Language[lang].SubjectText ? l.Language[lang].SubjectText : "")}})
        options.push({value: "new", label: t('create_new_playlist')})

        return (
            <div id={"tileContainer"} className={"listContainer"}>
                {!loading ? (
                    <>
                        {removing ? (
                            <div className={"infoPopup"}>
                                <div className={"closeBtn"} onClick={() => {this.setState({removing: false})}}>
                                    <img src={"/icons/cross.png"}/>
                                </div>
                            <div className={"actions"}>
                                <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                    <div className="inner">
                                        <li>
                                            <strong>{t('remove_disclaimer')}</strong>
                                        </li>
                                    </div>
                                </div>
                                <div className={"trackButton"} onClick={() => {this.removeUserList()}}>{t('remove_userlist')}</div>
                            </div>
                            </div>
                        ) : null}
                        {currentTrack ? (<div className={"infoPopup"}>
                            <div className={"closeBtn"} onClick={() => {this.setState({currentTrack: null, addToUserlist: false, selectedUserList: null, existsInList: false, addNew: false})}}>
                                <img src={"/icons/cross.png"}/>
                            </div>
                            <div className={"trackInfo"}>{currentTrack ? currentTrack.Artist : ""} - {currentTrack ? currentTrack.Title : ""}</div>
                            {addToUserlist ? (
                                <div className={"actions"}>
                                    { error ? (
                                        <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                            <div className="inner">
                                                <li>
                                                    <strong>{error}</strong>
                                                </li>
                                            </div>
                                        </div>
                                    ) : null}
                                    {userLists.length > 0 && !addNew ? <Select options={options} onChange={(event) => {event.value === "new" ? this.setState({addNew: true}) : this.setState({selectedUserList: event.value})}}/> : <input name="name" type="text"
                                                                                                                                                                      value={this.state.addName}
                                                                                                                                                                      placeholder={t('playlist_name')}
                                                                                                                                                                      onChange={(e) => {
                                                                                                                                                                          this.setState({addName: e.target.value})
                                                                                                                                                                      }} className="input"></input>}
                                    <div className={"trackButton"} onClick={() => {this.addSongToUserList(currentTrack.SongID)}}>{t('add_confirm')}</div>
                                </div>
                            ) : existsInList ? (
                                <div className={"actions"}>
                                    <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                        <div className="inner">
                                            <li>
                                                <strong>{t('already_exists')}</strong>
                                            </li>
                                        </div>
                                    </div>
                                    <div className={"trackButton"} onClick={() => {this.addSongToUserList(currentTrack.SongID, true)}}>{t('add_confirm')}</div>
                                </div>
                            ) : (
                                <div className={"actions"}>
                                    <div className={"trackButton"} onClick={() => {addToPlaylist(currentTrack, true, true); this.setState({currentTrack: null})}}>{t('play_now')}</div>
                                    <div className={"trackButton"} onClick={() => {addToPlaylist(currentTrack, true); this.setState({currentTrack: null})}}>{t('play_next')}</div>
                                    <div className={"trackButton"} onClick={() => {addToPlaylist(currentTrack); this.setState({currentTrack: null})}}>{t('add_track')}</div>
                                    {user ? <div className={"trackButton"} onClick={() => {this.removeFromUserlist(currentTrack.index); this.setState({currentTrack: null})}}>{t('delete_from_userlist')}</div> : null }
                                    <div className={"trackButton"} onClick={() => {this.setState({addToUserlist: true})}}>{t('add_to_userlist')}</div>
                                </div>
                            )}
                        </div>) : null}
                        {addListToList ? <div className={"infoPopup"}>
                            <div className={"closeBtn"} onClick={() => {this.setState({addListToList: false, selectedUserList: null, addNew: false})}}>
                                <img src={"/icons/cross.png"}/>
                            </div>
                            <div className={"trackInfo"}>{listTitle}</div>
                            <div className={"actions"}>
                                { error ? (
                                    <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                        <div className="inner">
                                            <li>
                                                <strong>{error}</strong>
                                            </li>
                                        </div>
                                    </div>
                                ) : null}
                                {userLists.length > 0 && !addNew ? <Select options={options} onChange={(event) => {event.value === "new" ? this.setState({addNew: true}) : this.setState({selectedUserList: event.value})}}/> : <input name="name" type="text"
                                                                                                                                                                                                                                       value={this.state.addName}
                                                                                                                                                                                                                                       placeholder={t('playlist_name')}
                                                                                                                                                                                                                                       onChange={(e) => {
                                                                                                                                                                                                                                           this.setState({addName: e.target.value})
                                                                                                                                                                                                                                       }} className="input"></input>}
                            <div className={"trackButton"} onClick={() => {this.addListToUserList()}}>{t('add_to_userlist')}</div>
                        </div></div> : null}
                        {!mobile && id ? <div className={"listHeader"}><img onClick={() => {
                            history.goBack()
                        }} id={"backBtn"} className={"backButton"} src={"/icons/back.svg"}/><div className={"listTitle"}>{listTitle}</div>{user ? <div className={"addButton left"} onClick={() => this.setState({removing: true})}>{t('remove_userlist')}</div> : <div className={"addButton left"} onClick={() => this.setState({addListToList: true})}>{t('add_to_userlist')}</div> }<div className={"addButton" + (active ? " active" : "")} onClick={() => addPlaylist(list, user)}>{active ? t('remove_playlist') : t('add_playlist')}</div></div> : null}
                        <input className={"searchBox"} placeholder={t('search') + "..."} onChange={e => this.onSearch(e.target.value)} />
                        <ScrollingWrapper mobile={mobile}>
                            <div className={"playlistList"}>
                                {search && searchResults.length < 1 ?
                                    <div className={"noResults"}>{t('no_results')}...</div>
                                    : ( search ?
                                        searchResults.map((item, index) => {
                                            return (
                                                <PlaylistItem key={index} item={item} selected={selectedSong === item.SongID} openDetails={() => {this.setState({currentTrack: {...item, index}})}} setSelected={() => {this.setState({selectedSong: selectedSong === item.SongID ? null : item.SongID})}} />
                                            )
                                        })
                                    :(list && list.Songs && list.Songs.length > 0) ? list.Songs.map((item, index) => {
                                        return (
                                            <PlaylistItem key={index} item={item} selected={selectedSong === index} openDetails={() => {this.setState({currentTrack: {...item, index}})}} setSelected={() => {this.setState({selectedSong: selectedSong === index ? null : index})}}/>
                                        )
                                    }) : <div className={"noResults"}>{t('no_results')}</div>)
                                }
                            </div>
                        </ScrollingWrapper>
                    </>
                ) : (
                    <div className={"loadingSpinner"}>
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={"loadingText"}>
                            {t('songs') + " " + t('loading') + "..."}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(withTranslation()(ListContent));
