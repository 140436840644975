import React, {Component} from 'react';
import {
    withRouter,
    Link
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './mobile-menu.scss';

class MobileMenu extends Component {

    render() {
        const currentPath = this.props.location.pathname;
        const { active, interactive, t } = this.props;
        let page = "";
        switch(true) {
            case currentPath.startsWith("/player/favorites"):
                page = "favorites"
                break;
            case currentPath.startsWith("/player/themes"):
                page = "themes"
                break;
            case currentPath.startsWith("/player/active"):
                page = "active"
                break;
            case currentPath.startsWith("/player/playlists"):
                page = "playlists"
                break;
            case currentPath.startsWith("/player/lists"):
                page = "lists"
                break;
            case currentPath.startsWith("/player/queue"):
                page = "queue"
                break;
            case currentPath.startsWith("/player/search"):
                page = "search"
                break;
        }

        if (interactive) {
            return (
                <div className={"mobileMenuContainer"}>
                    <Link className={"link"} to='/player/lists/themes'>
                        <button className={"menuButton" + (page === "lists" ? " active" : "")}>
                            <img src={page !== "lists" ? "/icons/menu/themes.svg" : "/icons/menu/themes_active.svg"} />
                            <div className={"buttonText"}>{t('playlists')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/active'>
                        <button className={"menuButton" + (page === "active" ? " active" : "")}>
                            { active && active > 0 ? (<div className={"activeCount"}>{active}</div>) : null}
                            <img src={page !== "active" ? "/icons/menu/active.svg" : "/icons/menu/active_active.svg"} />
                            <div className={"buttonText"}>{t('active_lists')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/queue'>
                        <button className={"menuButton" + (page === "queue" ? " active" : "")}>
                            <img src={page !== "queue" ? "/icons/menu/lists.svg" : "/icons/menu/lists_active.svg"} />
                            <div className={"buttonText"}>{t('queue')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/search'>
                        <button className={"menuButton" + (page === "search" ? " active" : "")}>
                            <img src={page !== "search" ? "/icons/menu/search.svg" : "/icons/menu/search_active.svg"} />
                            <div className={"buttonText"}>{t('search')}</div>
                        </button>
                    </Link>
                </div>
            )
        } else {
            return (
                <div className={"mobileMenuContainer"}>
                    <Link className={"link"} to='/player/favorites'>
                        <button className={"menuButton" + (page === "favorites" ? " active" : "")}>
                            <img src={page !== "favorites" ? "/icons/menu/favorite.svg" : "/icons/menu/favorite_active.svg"} />
                            <div className={"buttonText"}>{t('favorites')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/themes'>
                        <button className={"menuButton" + (page === "themes" ? " active" : "")}>
                            <img src={page !== "themes" ? "/icons/menu/themes.svg" : "/icons/menu/themes_active.svg"} />
                            <div className={"buttonText"}>{t('themes')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/active'>
                        <button className={"menuButton" + (page === "active" ? " active" : "")}>
                            { active && active > 0 ? (<div className={"activeCount"}>{active}</div>) : null}
                            <img src={page !== "active" ? "/icons/menu/active.svg" : "/icons/menu/active_active.svg"} />
                            <div className={"buttonText"}>{t('active_lists')}</div>
                        </button>
                    </Link>
                    <Link className={"link"} to='/player/playlists'>
                        <button className={"menuButton" + (page === "playlists" ? " active" : "")}>
                            <img src={page !== "playlists" ? "/icons/menu/lists.svg" : "/icons/menu/lists_active.svg"} />
                            <div className={"buttonText"}>{t('all_lists')}</div>
                        </button>
                    </Link>
                </div>
            )
        }
    }
}

export default withRouter(withTranslation()(MobileMenu));
