import axios from 'axios';
import qs from 'qs';
import authHeader from './auth-header';
import {API_BASE_URL} from "../config";
const API_URL = API_BASE_URL + '/client/';

class PlaylistService {
    getAllPlaylists() {
        return axios.get(API_URL + 'playlists', { headers: authHeader() })
    }
    getUserPlaylists() {
        return axios.get(API_URL + 'user_playlists', { headers: authHeader() })
    }
    getPlaylists(playlistIds) {
        return axios.get(API_URL + 'playlists', { headers: authHeader(), params: { playlistIds: playlistIds}})
    }
    getPlaylist(playlistId) {
        return axios.get(API_URL + 'playlists/' + playlistId, { headers: authHeader() })
    }

    getUserPlaylist(playlistId) {
        return axios.get(API_URL + 'user_playlists/' + playlistId, { headers: authHeader() })
    }
    createPlaylist(isrcCodes) {
        return axios.post(API_URL + 'playlists/convert_spotify', {
            isrcCodes
        }, {headers: authHeader()})
    }

    createUserPlaylist(title) {
        return axios.post(API_URL + 'playlists/create', {
            title
        }, {headers: authHeader()})
    }

    addToUserPlaylist(songId, playlistId) {
        return axios.patch(API_URL + 'playlists/update', {
            songId, playlistId
        }, {headers: authHeader()})
    }

    removeFromUserPlaylist(songIndex, playlistId) {
        return axios.patch(API_URL + 'playlists/update', {
            songIndex, playlistId, remove: true
        }, {headers: authHeader()})
    }

    addSongsToUserPlaylist(songIds, playlistId) {
        return axios.patch(API_URL + 'playlists/push', {
            playlistId, songIds
        }, {headers: authHeader()})
    }

    removeUserPlaylist(playlistId) {
        return axios.delete(API_URL + 'user_playlists/remove/' + playlistId, { headers: authHeader() })
    }
}

export default new PlaylistService();
