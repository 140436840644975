import axios from "axios";
import {API_BASE_URL} from "../config";
import authHeader from './auth-header';

const API_URL = API_BASE_URL + '/auth/';

class AuthService {
    login(email, password, name, overwriteId) {
        return axios
            .post(API_URL + "sign-in", {
                email,
                password,
                name,
                overwriteId
            }, { headers: {
                    'Content-Type': 'application/json',
                    'application': 'bcm'
                }})
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            }).catch((error) => {
                throw error;
            });
    }
    loginDemo(interactive) {
        return axios
            .post(API_URL + "signin_demo", {interactive}, { headers: {
                    'Content-Type': 'application/json',
                    'application': 'bcm'
                }})
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            }).catch((error) => {
                throw error;
            });
    }
    logout() {
        const user = JSON.parse(localStorage.getItem('user'));
        localStorage.removeItem("user");
        if(user && user.deviceId) {
            axios
                .post(API_URL + "logout", {
                    deviceId: user.deviceId,
                }, { headers: { Authorization: 'Bearer ' + user.accessToken, DeviceId: user.deviceId, Application: 'bcm' } })
                .then(response => {
                }).catch((error) => {
                throw error;
            });
        }
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));;
    }
}

export default new AuthService();
