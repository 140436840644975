import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en-US/translation.json';
import translationNL from './locales/nl-NL/translation.json';
import translationDE from './locales/de-DE/translation.json';

const resources = {
    'en-US': {
        translation: translationEN
    },
    'nl-NL': {
        translation: translationNL
    },
    'de-DE': {
        translation: translationDE
    }
};


i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en-US',
        debug: true,
        resources,
        supportedLngs: ['de-DE', 'en-US', 'nl-NL'],
        load: 'currentOnly',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
