import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './search.scss';
import ScrollingWrapper from "../../../components/ScrollingWrapper";
import songService from "../../../services/songService";
import {PlaylistItem} from "./components/playlistItem";
import Select from "react-select";
import playlistService from "../../../services/playlistService";
import authService from "../../../services/authService";
import _ from "underscore";

let timeOut = null;

class Search extends Component {
    constructor() {
        super();

        this.state = {
            search: "",
            searchResults: [],
            userLists: [],
            currentTrack: null,
            selectedSong: null,
            searching: false,
            error: null,
            addToUserlist: false,
            existsInList: false
        };
    }

    componentDidMount() {
        this.fetchUserLists()
    }

    fetchUserLists() {
        this.setState({userLists: []});
        playlistService.getUserPlaylists().then(({data}) => {
            this.setState({userLists: data.data})
        }).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/bcm-stream-trials")
                }
            }
        });
    }

    addSongToUserList = async (songId, accepted) => {
        const {t} = this.props;
        const {selectedUserList, userLists} = this.state;

        if (selectedUserList) {
            const playlist = _.findWhere(userLists, {_id: selectedUserList});
            if (!accepted && playlist && playlist.Songs.includes(songId)) {
                this.setState({existsInList: true, addToUserlist:false})
            } else {
                playlistService.addToUserPlaylist(songId, selectedUserList).then(() => {
                    this.fetchUserLists();
                }).catch(error => {
                    if (error.response) {
                        const {status} = error.response;
                        if (status === 403 || status === 401 || status === 404) {
                            this.logout();
                        } else if (status === 605) {
                            authService.logout();
                            window.location.replace("https://bcmstream.com/bcm-stream-trials")
                        }
                    }
                });

                this.setState({loading: false, selectedUserList: null, currentTrack: null})
            }
        } else {
            this.setState({error: t('error_no_list')})
        }
    }

    onSearch = (e) => {
        if (e.target.value.length > 1) {
            clearTimeout(timeOut);
            timeOut = setTimeout( () => {
                this.setState({searching: true})
                songService.searchSongs(e.target.value).then(({data}) => {
                    this.setState({searchResults: data.data ? data.data : [], searching: false})
                }).catch(() => {
                    this.setState({searchResults: [], searching: false})
                });
            }, 700);
        } else {
            this.setState({searchResults: [], searching: false})
        }
    }

    onEnter = (e) => {
        if (e.charCode === 13 && e.target.value.length < 4) {
            e.preventDefault();
            clearTimeout(timeOut);
            this.setState({searching: true})
            songService.searchSongs(e.target.value).then(({data}) => {
                this.setState({searchResults: data.data ? data.data : [], searching: false})
            }).catch(() => {
                this.setState({searchResults: [], searching: false})
            });
        }
    }

    render() {
        const {mobile, t, location, addToPlaylist} = this.props;
        const {searchResults, currentTrack, searching, addToUserlist, userLists, selectedSong, existsInList, error} = this.state;

        const params = new URLSearchParams(location.search);
        const searchFocus = params.get("s");

        const {i18n} = this.props;
        const lang = i18n.language.substr(0,2);
        const options = userLists.map((l) => {return { value: l._id, label: (l.Language && l.Language[lang] && l.Language[lang].SubjectText ? l.Language[lang].SubjectText : "")}})


        return (
            <div className={"searchContainer"}>
                {currentTrack ? (<div className={"infoPopup"}>
                    <div className={"closeBtn"} onClick={() => {
                        this.setState({currentTrack: null, addToUserlist: false})
                    }}>
                        <img src={"/icons/cross.png"}/>
                    </div>
                    <div
                        className={"trackInfo"}>{currentTrack ? currentTrack.Artist : ""} - {currentTrack ? currentTrack.Title : ""}</div>

                        { addToUserlist ? (
                            <div className={"actions"}>
                                { error ? (
                                    <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                        <div className="inner">
                                            <li>
                                                <strong>{error}</strong>
                                            </li>
                                        </div>
                                    </div>
                                ) : null}
                                <Select options={options} onChange={(event) => {this.setState({selectedUserList: event.value})}}/>
                                <div className={"trackButton"} onClick={() => {this.addSongToUserList(currentTrack.SongID)}}>{t('add_confirm')}</div>
                            </div>
                        ) : existsInList ? (
                            <div className={"actions"}>
                                <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                    <div className="inner">
                                        <li>
                                            <strong>{t('already_exists')}</strong>
                                        </li>
                                    </div>
                                </div>
                                <div className={"trackButton"} onClick={() => {this.addSongToUserList(currentTrack.SongID, true)}}>{t('add_confirm')}</div>
                            </div>
                        ) : (<div className={"actions"}>
                            <div className={"trackButton"} onClick={() => {addToPlaylist(currentTrack, true, true); this.setState({currentTrack: null})}}>{t('play_now')}</div>
                            <div className={"trackButton"} onClick={() => {
                            addToPlaylist(currentTrack, true);
                            this.setState({currentTrack: null})
                        }}>{t('play_next')}</div>
                        <div className={"trackButton"} onClick={() => {
                            addToPlaylist(currentTrack);
                            this.setState({currentTrack: null})
                        }}>{t('add_track')}</div>
                        <div className={"trackButton"} onClick={() => {this.setState({addToUserlist: true})}}>
                            {t('add_to_userlist')}</div>
                    </div>)}
                </div>) : null}
                {(
                    <>
                        <input className={"searchBox"} placeholder={t('search') + "..."} autoFocus={searchFocus}
                               onInput={e => this.onSearch(e)} onKeyPress={e => this.onEnter(e)}/>
                        <ScrollingWrapper mobile={mobile}>
                            {
                                !searching ?
                                    searchResults.map((item, index) => {
                                        return (
                                            <PlaylistItem key={index} item={item} openDetails={() => {
                                                this.setState({currentTrack: item})
                                            }} selected={selectedSong === item.SongID} setSelected={() => {this.setState({selectedSong: selectedSong === item.SongID ? null : item.SongID})}}/>
                                        )
                                    }) :
                                    <div className={"loadingSpinner"}>
                                        <div className="lds-roller">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div className={"loadingText"}>
                                            {t('songs') + " " + t('loading') + "..."}
                                        </div>
                                    </div>
                                }
                        </ScrollingWrapper>
                    </>
                )}
            </div>
        )
    }
}

export default withRouter(withTranslation()(Search));
