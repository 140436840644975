import React, {Component} from 'react';
import _ from 'underscore';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './playlist.scss';
import ScrollingWrapper from "../../../components/ScrollingWrapper";
import Sortable from 'sortablejs';
import {PlaylistItem} from "./components/playlistItem";
import Select from "react-select";
import playlistService from "../../../services/playlistService";
import authService from "../../../services/authService";

class Playlist extends Component {
    constructor() {
        super();

        this.state = {
            currentTrack: null,
            selectedSong: null,
            addToUserlist: false,
            userLists: [],
            error:null,
            loading: true,
            existsInList: false,
            addName: "",
            addNew: false
        };
    }

    fetchUserLists = () => {
        this.setState({userLists: []});
        playlistService.getUserPlaylists().then(({data}) => {
            this.setState({userLists: data.data})
        }).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/bcm-stream-trials")
                }
            }
        });
    }

    addSongToUserList = async (songId, accepted) => {
        const {t} = this.props;
        const {selectedUserList, userLists, addName} = this.state;

        let listId = selectedUserList ? selectedUserList : null;

        if (addName) {
            await playlistService.createUserPlaylist(addName).then(({data}) => {
                listId = data.id;
                this.setState({addNew: false, addName: null});
                this.fetchUserLists();
            }).catch(error => {
                if(error.response) {
                    const { status } = error.response;
                    if(status === 403 || status === 401) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/bcm-stream-trials")
                    }
                }
            });
        }

        if (listId) {
            const playlist = _.findWhere(userLists, {_id: listId});
            if (!accepted && playlist && playlist.Songs.includes(songId)) {
                this.setState({existsInList: true, addToUserlist:false})
            } else {
                playlistService.addToUserPlaylist(songId, listId).then(() => {
                    this.fetchUserLists();
                }).catch(error => {
                    if (error.response) {
                        const {status} = error.response;
                        if (status === 403 || status === 401 || status === 404) {
                            this.logout();
                        } else if (status === 605) {
                            authService.logout();
                            window.location.replace("https://bcmstream.com/bcm-stream-trials")
                        }
                    }
                });

                this.setState({loading: false, selectedUserList: null, existsInList: false, addToUserlist:false, currentTrack: null})
            }
        } else {
            this.setState({error: t('error_no_list')})
        }
    }

    componentDidMount() {
        const { moveSong } = this.props;

        let el = document.getElementById('playlist');
        let sortable = new Sortable(el, {
            animation: 150,  // ms, animation speed moving items when sorting, `0` — without animation
            delay: 150, // time in milliseconds to define when the sorting should start
            easing: "cubic-bezier(1, 0, 0, 1)", // Easing for animation. Defaults to null. See https://easings.net/ for examples.
            preventOnFilter: true, // Call `event.preventDefault()` when triggered `filter`
            draggable: ".playlistItem",  // Specifies which items inside the element should be draggable
            ghostClass: "sortable-ghost",  // Class name for the drop placeholder
            chosenClass: "sortable-chosen",  // Class name for the chosen item
            dragClass: "sortable-drag",  // Class name for the dragging item
            filter: ".first",
            onEnd:  (evt) => {
                moveSong(evt.oldIndex, evt.newIndex);
            },
        });

        this.fetchUserLists()
    }

    render() {
        const {playlist, songHistory, mobile, t, moveSong, emptyPlayer, selectedSong, currentInfo} = this.props;
        const {currentTrack, addToUserlist, userLists, existsInList, addNew, error} = this.state;
        const {i18n} = this.props;

        const lang = i18n.language.substr(0,2);

        const currentSong = currentInfo && currentInfo.current ? currentInfo.current : null;
        const nextSong =  currentInfo && currentInfo.next ? currentInfo.next : null;

        const options = userLists.map((l) => {return { value: l._id, label: (l.Language && l.Language[lang] && l.Language[lang].SubjectText ? l.Language[lang].SubjectText : "")}})
        options.push({value: "new", label: t('create_new_playlist')})

        return (
            <div className={"playlistContainer"}>
                {
                    <>
                        {currentTrack ? (<div className={"infoPopup"}>
                            <div className={"closeBtn"} onClick={() => {this.setState({currentTrack: null, addToUserlist: false, existsInList: false, addNew: false})}}>
                                <img src={"/icons/cross.png"}/>
                            </div>
                            <div className={"trackInfo"}>{currentTrack ? currentTrack.Artist : ""} - {currentTrack ? currentTrack.Title : ""}</div>
                                { addToUserlist ? (
                                        <div className={"actions"}>
                                            { error ? (
                                                <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                                    <div className="inner">
                                                        <li>
                                                            <strong>{error}</strong>
                                                        </li>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {userLists.length > 0 && !addNew ? <Select options={options} onChange={(event) => {event.value === "new" ? this.setState({addNew: true}) : this.setState({selectedUserList: event.value})}}/> : <input name="name" type="text"
                                                                                                                                                                                                                                                   value={this.state.addName}
                                                                                                                                                                                                                                                   placeholder={t('playlist_name')}
                                                                                                                                                                                                                                                   onChange={(e) => {
                                                                                                                                                                                                                                                       this.setState({addName: e.target.value})
                                                                                                                                                                                                                                                   }} className="input"></input>}
                                            <div className={"trackButton"} onClick={() => {this.addSongToUserList(currentTrack.SongID)}}>{t('add_confirm')}</div>
                                        </div>
                                    ) : existsInList ? (
                                        <div className={"actions"}>
                                            <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                                                <div className="inner">
                                                    <li>
                                                        <strong>{t('already_exists')}</strong>
                                                    </li>
                                                </div>
                                            </div>
                                            <div className={"trackButton"} onClick={() => {this.addSongToUserList(currentTrack.SongID, true)}}>{t('add_confirm')}</div>
                                        </div>
                                    ) : (
                            <div className={"actions"}>
                                <div className={"trackButton"} onClick={() => {moveSong(currentTrack.index - 1, 0, true); this.setState({currentTrack: null})}}>{t('play_now')}</div>
                                <div className={"trackButton"} onClick={() => {moveSong(currentTrack.index - 1, 0); this.setState({currentTrack: null})}}>{t('play_next')}</div>
                                <div className={"trackButton"} onClick={() => {moveSong(currentTrack.index); this.setState({currentTrack: null})}}>{t('delete_track')}</div>
                                <div className={"trackButton"} onClick={() => {this.setState({addToUserlist: true})}}>{t('add_to_userlist')}</div>
                            </div>)}
                        </div>) : null}
                        <ScrollingWrapper mobile={mobile}>
                            {currentSong ? <PlaylistItem item={currentSong} first={true}/> : null}
                            <div className={"playlist"} id={"playlist"}>
                                {nextSong ?  <PlaylistItem item={nextSong} openDetails={() => this.setState({currentTrack: {...nextSong, index: 1}})} selected={selectedSong === nextSong.SongID} setSelected={() => {this.setState({selectedSong: nextSong.SongID})}}/> : null}
                                {playlist.map((item, index) => {
                                    return (
                                        <PlaylistItem item={item} openDetails={() => this.setState({currentTrack: {...item, index: index + 2}})} selected={selectedSong === item.SongID} setSelected={() => {this.setState({selectedSong: selectedSong === item.SongID ? null : item.SongID})}}/>
                                    )
                                })}
                            </div>
                        </ScrollingWrapper>
                    </>
                }
            </div>
        )
    }
}

export default withRouter(withTranslation()(Playlist));
