import React, {Component} from 'react';
import {
    Switch,
    Route,
    Redirect,
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './content.scss';

import Active from "./active";
import Tiles from "./tiles";
import Playlist from './playlist';
import ListsMenu from "./listsMenu";
import Search from "./search";
import ListContent from "./listContent";

class Content extends Component {

    render() {
        const {allPlaylists, active, favorites, playlist, songHistory, emptyPlayer, addFavorite, addPlaylist, changePercentage, moveSong, addToPlaylist, currentInfo, interactive, mobile} = this.props;

        return (
            <div className={"mobileContentContainer"}>
                    <Switch>
                        <Route exact path="/player">
                            {interactive ? <Redirect to="/player/lists" /> : <Redirect to="/player/themes" />}
                        </Route>
                        <Route path="/player/favorites">
                            <Tiles page={"favorites"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} themes mobile={mobile}/>
                        </Route>
                        <Route path="/player/themes">
                            <Tiles page={"themes"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} themes mobile={mobile}/>
                        </Route>
                        <Route path="/player/active">
                            <Active activeLists={active} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} changePercentage={changePercentage} interactive={interactive} mobile={mobile}/>
                        </Route>
                        <Route path="/player/playlists">
                            <Tiles page={"playlists"} allPlaylists={allPlaylists} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} mobile={mobile}/>
                        </Route>
                        <Route path="/player/queue">
                            <Playlist page={"playlist"} playlist={playlist} songHistory={songHistory} moveSong={moveSong} currentInfo={currentInfo} mobile={mobile} emptyPlayer={emptyPlayer} />
                        </Route>
                        <Route path="/player/lists/*">
                            <ListsMenu />
                            <Route path="/player/lists/themes">
                                <Tiles page={"themes"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} themes mobile={mobile} interactive={interactive}/>
                            </Route>
                            <Route path="/player/lists/playlists">
                                <Tiles page={"playlists"} allPlaylists={allPlaylists} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} mobile={mobile} interactive={interactive}/>
                            </Route>
                            <Route path="/player/lists/user">
                                <Tiles page={"user"} addPlaylist={addPlaylist} favorites={favorites} addFavorite={addFavorite} activeLists={active} mobile={mobile} user={true} interactive={interactive}/>
                            </Route>
                            <Route path="/player/lists/favorites">
                                <Tiles page={"favorites"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} themes mobile={mobile} interactive={interactive}/>
                            </Route>
                        </Route>
                        <Route exact={true} path="/player/list/:id">
                            <ListContent addToPlaylist={addToPlaylist} addPlaylist={addPlaylist} activeLists={active} user={false}/>
                        </Route>
                        <Route exact={true} path="/player/list/user/:id">
                            <ListContent addToPlaylist={addToPlaylist} addPlaylist={addPlaylist} activeLists={active} user={true} />
                        </Route>
                        <Route path="/player/search">
                            <Search mobile={mobile} addToPlaylist={addToPlaylist} />
                        </Route>
                        <Route path="*">
                            {interactive ? <Redirect to="/player/lists/themes" /> : <Redirect to="/player/themes" />}
                        </Route>
                    </Switch>
            </div>
        )
    }
}

export default withRouter(withTranslation()(Content));
