import React from 'react';
import './playlistItem.scss';

export const PlaylistItem = ({item, first, openDetails, setSelected, selected}) => {
    return (
        <div key={item?.SongID} className={"playlistItem" + (first ? " first" : "") + (selected ? " selected" : "")} onClick={() => !first ? setSelected() : null}>
            <div className={"songDetails"}>
                <div className={"songTitle"}>{item?.Title}</div>
                <div className={"songArtist"}>{item?.Artist}</div>
            </div>
            <div className={"action"} onClick={() => !first ? openDetails() : null}>
                {first ? <img className={"play"} src={"/icons/play.svg"}/> : <img src={"/icons/more.png"}/>}
            </div>
        </div>
    )
}
