import React, {Component, forwardRef} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './music-player.scss';
import TrackPlayer from "./track-player";

class MusicPlayer extends Component {

    constructor() {
        super();

        this.loadNextTrack = this.loadNextTrack.bind(this);

        this.state = {
            currentPlayer: 1,
            playing: false,
            trackOne: null,
            trackTwo: null,
            paused: false
        };
    }

    componentDidMount() {
        this.props.setLoadNext(this.loadNextTrack);
        if ('mediaSession' in navigator) {
            navigator.mediaSession.setActionHandler('pause', () => {
            });
        }
        const {playlist} = this.props;
        if(playlist.length > 0) {
            this.fillPlayer();
            this.checkAutoPlay(playlist);
        }
    }

    callback_onAutoplayBlocked() {
        //this.setState({showPlay: true});
    }

    isSafari() {
        var chr = window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
        var sfri = window.navigator.userAgent.toLowerCase().indexOf("safari") > -1;
        return !chr && sfri;
    }

    checkAutoPlay() {
        const {currentPlayer} = this.state;
        let player = document.getElementById(`player-${currentPlayer}`);
        let p = player.play();

        var s = window['Promise'] ? window['Promise'].toString() : '';

        if (s.indexOf('function Promise()') !== -1 || s.indexOf('function ZoneAwarePromise()') !== -1) {
            p.then(() => {
                this.startPlaying(currentPlayer);
            }).catch((error) => {
                error = true;
                if(error.name === "NotAllowedError") { // For Chrome/Firefox
                    this.callback_onAutoplayBlocked();
                } else if (error.name === "AbortError" && this.isSafari()) {  // Only for Safari
                    this.callback_onAutoplayBlocked();
                } else {
                    this.callback_onAutoplayBlocked();
                }
            })
        } else {
           // didn't work
        }
    }

    componentDidUpdate(oldProps) {
        const {playlist} = this.props;
        const {playing} = this.state;

        if (playlist.length > 0 && oldProps.playlist.length === 0) {
            this.fillPlayer();

            if (!playing) {
                this.checkAutoPlay(playlist);
            }
        }
    }

    fillPlayer() {
        const {getNextSong} = this.props;
        const {trackOne, trackTwo} = this.state;
        this.setState({trackOne: !trackOne ? getNextSong() : trackOne, trackTwo: !trackTwo ? getNextSong() : trackTwo}, () => {
            this.setCurrentInfo();
        })
    }

    startPlaying(player) {
        let player1 = document.getElementById(`player-1`);
        let player2 = document.getElementById(`player-2`);

        player1.play();
        player2.play();
        if(player === 1) {
            player2.pause();
        } else {
            player1.pause();
        }
        this.setState({playing: true, currentPlayer: player})
    }

    playNextTrack(player) {
        const nextPlayer = player === 2 ? 1 : 2;
        this.setState({currentPlayer: nextPlayer});
    }

    loadNextTrack(player, playNow, keepSongs) {
        const {getNextSong} = this.props;
        const {currentPlayer} = this.state;

        if (!keepSongs) {
            if (!player) {
                player = currentPlayer === 1 ? 2 : 1;
            }

            const playNext = (playNow) => {
                if (playNow) {
                    currentPlayer === 1 ? this.removeSongOne() : this.removeSongTwo()
                }
            }

            if(player === 1) {
                this.setState({trackOne: getNextSong()}, () => {this.setCurrentInfo(); playNext(playNow)})
            } else if (player === 2) {
                this.setState({trackTwo: getNextSong()}, () => { this.setCurrentInfo(); playNext(playNow)})
            }
        } else {
            if (playNow) {
                currentPlayer === 1 ? this.removeSongOne() : this.removeSongTwo()
            }
        }

    }

    setCurrentInfo() {
        const {currentPlayer, trackOne, trackTwo} = this.state;
        this.props.setCurrentInfo(currentPlayer === 1 ? {current: trackOne, next: trackTwo} : {current: trackTwo, next: trackOne });
    }

    getCurrentTrack() {
        const {currentPlayer, trackOne, trackTwo} = this.state;
        if(currentPlayer === 1) {
            return trackOne;
        } else {
            return trackTwo;
        }
    }

    getNextTrack() {
        const {currentPlayer, trackOne, trackTwo} = this.state;
        if(currentPlayer === 1) {
            return trackTwo;
        } else {
            return trackOne;
        }
    }


    render() {
        const {currentPlayer, playing, paused, trackOne, trackTwo} = this.state;
        const {activePlaylists, addBlocked, interactive} = this.props;
        const volume = 100;
        const fadingTime = 3500;

        const nextAvailable = trackTwo && trackOne;
        const noTracks = !trackOne && !trackTwo;

        return (
                <div style={noTracks ? {display: 'none'} : {} } className={"musicPlayer"}>
                    <TrackPlayer playerID={1} forwardRef={this.trackPlayerOne} currentTrack={trackOne} nextTrack={trackTwo} nextAvailable={nextAvailable} mainPlaying={playing} playing={currentPlayer === 1 && playing} paused={currentPlayer === 1 && paused} display={currentPlayer === 1} volume={volume} fadingTime={fadingTime} activePlaylists={activePlaylists} play={()=> this.startPlaying(1)} playNext={()=> this.playNextTrack(1)} loadNext={() => this.loadNextTrack(1)} setRemoveSong={remove => this.removeSongOne = remove} addBlocked={addBlocked} interactive={interactive}/>
                    <TrackPlayer playerID={2} forwardRef={this.trackPlayerTwo} currentTrack={trackTwo} nextTrack={trackOne} nextAvailable={nextAvailable} mainPlaying={playing} playing={currentPlayer === 2 && playing} paused={currentPlayer === 2 && paused} display={currentPlayer === 2} volume={volume} fadingTime={fadingTime} activePlaylists={activePlaylists} play={()=> this.startPlaying(2)} playNext={() => this.playNextTrack(2)} loadNext={() => this.loadNextTrack(2)} setRemoveSong={remove => this.removeSongTwo = remove} interactive={interactive}/>
                </div>
        )
    }
}

export default withRouter(withTranslation()(MusicPlayer));
