import axios from 'axios';
import authHeader from './auth-header';
import {API_BASE_URL} from "../config";

const API_URL = API_BASE_URL + '/client/';

class SongService {
    getSong(songId) {
        return axios.get(API_URL + 'songs/' + songId, { headers: authHeader() })
    };
    searchSongs(search) {
        return axios.get(API_URL + 'songs/search/' + search, { headers: authHeader() })
    };
}

export default new SongService();
