import React, {Component} from 'react';
import {
    withRouter,
    Link
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './listsMenu.scss';

class ListsMenu extends Component {
    render() {
        const currentPath = this.props.location.pathname;
        const { t } = this.props;

        return (
            <div className={"listsMenu"}>
                <Link className={"link"} to='/player/lists/themes'>
                    <div className={"menuButton first" + (currentPath.startsWith("/player/lists/themes") ? " active" : "")}>
                        <div className={"buttonText"}>{t('themes')}</div>
                    </div>
                </Link>
                <Link className={"link"} to='/player/lists/playlists'>
                    <div className={"menuButton middle" + (currentPath.startsWith("/player/lists/playlists") ? " active" : "")}>
                        <div className={"buttonText"}>{t('all_lists')}</div>
                    </div>
                </Link>
                <Link className={"link"} to='/player/lists/user'>
                    <div className={"menuButton middle" + (currentPath.startsWith("/player/lists/user") ? " active" : "")}>
                        <div className={"buttonText"}>{t('own_lists')}</div>
                    </div>
                </Link>
                <Link className={"link"} to='/player/lists/favorites'>
                    <div
                        className={"menuButton last" + (currentPath.startsWith("/player/lists/favorites") ? " active" : "")}>
                        <div className={"buttonText"}>{t('favorites')}</div>
                    </div>
                </Link>
            </div>
        )
    }
}

export default withRouter(withTranslation()(ListsMenu));
